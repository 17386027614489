import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';

const JobDetails = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);

  useEffect(() => {
    async function fetchJobDetails() {
      const response = await axiosInstance.get(`jobs/${jobId}`);
      setJob(response.data);
    }
    fetchJobDetails();
  }, [jobId]);

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`jobs/${jobId}`);
      navigate('/');
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const renderTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <Typography variant="body2" key={index} paragraph>
        {line}
      </Typography>
    ));
  };

  if (!job) return <Typography>Loading...</Typography>;

  return (
    <Container sx={{ mt: 4 }}>
      {/* Title */}
      <Typography variant="h4" gutterBottom>
        {job.title}
      </Typography>
      
      {/* Description */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Description:
      </Typography>
      {renderTextWithLineBreaks(job.jobDescription)}

      {/* Requirements */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Requirements:
      </Typography>
      {renderTextWithLineBreaks(job.requirements)}

      {/* Benefits */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Benefits:
      </Typography>
      {renderTextWithLineBreaks(job.benefits)}

      {/* Levels */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Levels:
      </Typography>
      {job.levels.map((level) => (
        <Typography variant="body2" key={level.levelId}>
          - {level.levelName}
        </Typography>
      ))}

      {/* Locations */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Locations:
      </Typography>
      {job.locations.map((location) => (
        <Typography variant="body2" key={location.locationId}>
          - {location.locationName}
        </Typography>
      ))}

      <Box mt={3} display="flex" gap={2}>
        <Button
          variant="contained"
          component={Link}
          to={`/edit-job/${jobId}`}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button variant="contained" component={Link} to="/">
          Back to Job List
        </Button>
      </Box>
    </Container>
  );
};

export default JobDetails;
