// src/App.js
import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import JobList from './components/JobList';
import JobDetails from './components/JobDetails';
import CreateJob from './components/CreateJob';
import EditJob from './components/EditJob';

const App = () => {
  return (
    <Router>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{
              flexGrow: 1,
              textDecoration: 'none',
              color: 'inherit',
              cursor: 'pointer',
            }}
          >
            Job Management
          </Typography>
        </Toolbar>
      </AppBar>

      <Routes>
        <Route path="/" element={<JobList />} />
        <Route path="/job/:jobId" element={<JobDetails />} />
        <Route path="/create" element={<CreateJob />} />
        <Route path="/edit-job/:jobId" element={<EditJob />} />
      </Routes>
    </Router>
  );
};

export default App;