// src/components/JobList.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';

const JobList = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    async function fetchJobs() {
      const response = await axiosInstance.get('/jobs');
      setJobs(response.data);
    }
    fetchJobs();
  }, []);

  return (
    <Container sx={{ mt: 4 }}>
      <Button variant="contained" sx={{ mb: 2 }} component={Link} to="/create">
        Create Job
      </Button>
      <Grid container spacing={3}>
        {jobs.map((job) => (
          <Grid item xs={12} sm={6} md={4} key={job.jobId}>
            <Card>
              <CardContent>
                <Typography variant="h6">{job.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Salary: {job.salary}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Expiration: {job.expirationDate}
                </Typography>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    component={Link}
                    to={`/job/${job.jobId}`}
                  >
                    View Details
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default JobList;
