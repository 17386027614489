// src/components/EditJob.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';

const EditJob = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    salary: '',
    expirationDate: '',
    jobDescription: '',
    requirements: '',
    benefits: '',
    levels: [],
    locations: [],
  });
  const [levels, setLevels] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    async function fetchLevelsAndLocations() {
      try {
        const [levelsResponse, locationsResponse] = await Promise.all([
          axiosInstance.get('levels'),
          axiosInstance.get('locations'),
        ]);
        setLevels(levelsResponse.data);
        setLocations(locationsResponse.data);
      } catch (error) {
        console.error('Error fetching levels or locations:', error);
      }
    }
    fetchLevelsAndLocations();

    // Fetch the job details to edit
    async function fetchJobDetails() {
      try {
        const response = await axiosInstance.get(`jobs/${jobId}`);
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching job details:', error);
      }
    }
    fetchJobDetails();
  }, [jobId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e, type, id) => {
    setFormData((prevState) => {
      const updatedList = prevState[type].some((item) => item[`${type.slice(0, -1)}Id`] === id)
        ? prevState[type].filter((item) => item[`${type.slice(0, -1)}Id`] !== id)
        : [...prevState[type], { [`${type.slice(0, -1)}Id`]: id }];
      return {
        ...prevState,
        [type]: updatedList,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(`jobs/${jobId}`, formData);
      navigate(`/job/${jobId}`);
    } catch (error) {
      console.error('Error updating job:', error);
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Edit Job
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Salary"
          name="salary"
          value={formData.salary}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Expiration Date"
          type="date"
          name="expirationDate"
          value={formData.expirationDate}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Description"
          name="jobDescription"
          value={formData.jobDescription}
          onChange={handleChange}
          multiline
          rows={4}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Requirements"
          name="requirements"
          value={formData.requirements}
          onChange={handleChange}
          multiline
          rows={4}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Benefits"
          name="benefits"
          value={formData.benefits}
          onChange={handleChange}
          multiline
          rows={4}
          required
        />
        <Typography variant="h6" sx={{ mt: 3 }}>
          Select Levels
        </Typography>
        <FormGroup>
          {levels.map((level) => (
            <FormControlLabel
              key={level.levelId}
              control={
                <Checkbox
                  checked={formData.levels.some((l) => l.levelId === level.levelId)}
                  onChange={(e) => handleCheckboxChange(e, 'levels', level.levelId)}
                />
              }
              label={level.levelName}
            />
          ))}
        </FormGroup>
        <Typography variant="h6" sx={{ mt: 3 }}>
          Select Locations
        </Typography>
        <FormGroup>
          {locations.map((location) => (
            <FormControlLabel
              key={location.locationId}
              control={
                <Checkbox
                  checked={formData.locations.some((l) => l.locationId === location.locationId)}
                  onChange={(e) => handleCheckboxChange(e, 'locations', location.locationId)}
                />
              }
              label={location.locationName}
            />
          ))}
        </FormGroup>
        <Box mt={3}>
          <Button variant="contained" type="submit">
            Save Changes
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default EditJob;